var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Header',{attrs:{"childIndex":4}}),_c('div',{staticClass:"main_box",style:(!_vm.$UserID
				? 'height:980px; overflow: hidden;'
				: _vm.$ServiceType != 1
				? 'height:980px; overflow: hidden;'
				: 'height:auto; overflow: auto;')},[_c('div',{staticClass:"main_box1"},[_c('div',{staticClass:"title_box"},[_c('router-link',{attrs:{"to":{ path: '/' }}},[_c('p',[_vm._v("首页 >")])]),_c('router-link',{attrs:{"to":{ path: '/LegacyIndex' }}},[_c('p',[_vm._v("遗珍 >")])]),_c('span',[_vm._v("图片详情")])],1),_c('div',{staticClass:"book_detail img_detail"},[(_vm.ImageModelInfo)?_c('div',{staticClass:"img_detailCover"},[_c('img',{attrs:{"src":_vm.$ImgUrl +
								_vm.ImageModelInfo.ImageModel.image_file_path,"onerror":_vm.$parent.defaultImg1}})]):_vm._e(),(_vm.ImageModelInfo)?_c('div',{staticClass:"yjcg_box13 book_detailbox"},[_c('div',{staticClass:"detail_title"},[_c('h3',[_vm._v(_vm._s(_vm.ImageModelInfo.ImageModel.image_name))])]),_c('div',{staticClass:"qk_rightbox book_detailboxc img_detailboxc"},[_c('div',{staticClass:"qk_rightbox_b tp_rightbox_b"},[_c('i',{staticClass:"w_25"},[_c('p',[_vm._v("图片尺寸：")]),_c('span',[_vm._v(_vm._s(_vm.ImageModelInfo.ImageModel .image_width)+"*"+_vm._s(_vm.ImageModelInfo.ImageModel .image_height))])]),(
									_vm.ImageModelInfo.ImageModel.image_format
								)?_c('i',{staticClass:"w_25"},[_c('p',[_vm._v("文件格式：")]),_c('span',[_vm._v(_vm._s(_vm.ImageModelInfo.ImageModel.image_format))])]):_vm._e(),_c('i',{staticClass:"w_25"},[_c('p',[_vm._v("文件大小：")]),_c('span',[_vm._v(_vm._s(_vm._f("toFixed")((_vm.ImageModelInfo.ImageModel .image_file_size / 1024),2))+"KB")])]),(_vm.ImageModelInfo.ImageModel.res_name)?_c('i',{staticClass:"w100"},[_c('p',[_vm._v("图片来源：")]),_c('span',{staticClass:"lin_height20p_t14"},[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.toBookDetail(
												_vm.ImageModelInfo.ImageModel
													.res_id
											)}}},[_vm._v(_vm._s(_vm.ImageModelInfo.ImageModel .res_name))])])]):_vm._e(),(_vm.ImageModelInfo.Keywords.length > 0)?_c('i',{staticStyle:{"width":"calc( 100% - 320px )"}},[_c('p',[_vm._v("关键词:")]),_c('span',{staticClass:"lin_height20p_t14 p_t16"},_vm._l((_vm.ImageModelInfo.Keywords),function(value,index){return _c('a',{key:index,style:(value.type == 0 ||
											value.ReleaseStatus == 0
												? 'color:#000; cursor: default;'
												: 'color:#99533e'),attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.toElementDetail(value)}}},[_vm._v(_vm._s(value.keyword))])}),0)]):_vm._e()]),_c('div',{staticClass:"opreation_box"},[(_vm.collectStatus == 0)?_c('a',{staticClass:"detail_collection",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.InsertCollection()}}},[_vm._v("收 藏")]):_vm._e(),(_vm.collectStatus == 1)?_c('a',{staticClass:"detail_collection detail_collected",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.CancelCollection()}}},[_vm._v("已收藏")]):_vm._e()])]),_c('div',{staticClass:"yjcg_box13cb"},[_c('div',{staticClass:"yjcg_box13cbb"},[_vm._v(" "+_vm._s(_vm.ImageModelInfo.ImageModel.description)+" ")])])]):_vm._e(),_c('RelatedImage',{attrs:{"relatedType":3,"topNum":5,"resID":_vm.$route.query.id}}),_c('RelatedElement',{attrs:{"relatedType":3,"topNum":4,"resID":_vm.$route.query.id}}),_c('RelatedEntry',{attrs:{"relatedType":3,"topNum":12,"resID":_vm.$route.query.id}}),_c('RelatedBook',{attrs:{"relatedType":3,"topNum":8,"resID":_vm.$route.query.id}}),_c('RelatedAudio',{attrs:{"relatedType":3,"topNum":5,"resID":_vm.$route.query.id}}),_c('RelatedVideo',{attrs:{"relatedType":3,"topNum":6,"resID":_vm.$route.query.id}})],1)]),_c('VIPLayer',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$UserID || _vm.$ServiceType != 1),expression:"!$UserID || $ServiceType != 1"}],attrs:{"LayerHeight":340}})],1),_c('Footer'),_c('LoginPop',{attrs:{"showLogin":_vm.showLogin}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }