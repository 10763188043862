import Header from '../../Header.vue'
import Footer from '../../Footer.vue'
import RelatedBook from '../../Related/RelatedBook.vue'
import RelatedElement from '../../Related/RelatedElement.vue'
import RelatedEntry from '../../Related/RelatedEntry.vue'
import RelatedImage from '../../Related/RelatedImage.vue'
import RelatedAudio from '../../Related/RelatedAudio.vue'
import RelatedVideo from '../../Related/RelatedVideo.vue'
import LoginPop from '../../Login/LoginPop.vue'
import CheckInService from '../../../api/CheckInService.js'
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import axios from 'axios'
import VIPLayer from '../../../components/VIPLayer.vue'

export default{
	name:'ImageDetail',
	data(){
		return{
			ImageModelInfo:'',//详情信息
			collectStatus:0,//收藏状态
			showLogin:false,
			reviewImage:require('../../../images/review_ico.png'),
			imageList:[],
			fileimage:false
		}
	},
	methods:{
		GetImageModel(){ //获取详情
			var that = this
			CheckInService.GetImageModel(that.$route.query.id,that.$UserID).then(res=>{
				console.log(res)
				if(res.Tag == 1){
					if(res.Result.ImageModel.ReleaseState == 1 ){
						that.ImageModelInfo = res.Result
						that.collectStatus = res.Result.ComStatus
						that.imageList.push(that.$ImgUrl + that.ImageModelInfo.ImageModel.image_file_path)
					}else{
						that.$alert('该资源未发布', '提示', {
							confirmButtonText: '确定',
							callback: action => {
								if (action == "confirm") {
									window.close()
								}
								window.close()
							}
						});
					}
					
				}
			})
		},
		ReviewImage(){ //预览
			var that = this
			if(that.$UserID){
				if(that.$ServiceType != 1){ //非vip
					that.$alert('请联系平台管理员开通VIP服务', '提示', {
						confirmButtonText: '确定'
					});
				}else{
					that.fileimage = true
				}
			}else{
				that.showLogin = false
				that.showLogin = true
			}
		},
		DownloadImage(){ //下载
			var that = this
			if(that.$UserID){
				if(that.$ServiceType == 1){ //vip
					axios.get(that.ImageModelInfo.ImageModel.image_file_path, {
							responseType: 'blob'
						})
						.then(response => {
							const url = window.URL.createObjectURL(new Blob([response.data]))
							const link = document.createElement('a')
							link.href = url
							let fname = that.ImageModelInfo.ImageModel.image_name + '.' +that.ImageModelInfo.ImageModel.image_file_path.split('.')[1]					
							link.setAttribute('download', fname)
							document.body.appendChild(link)
							link.click()
						})
				}else{
					that.$alert('请联系平台管理员开通VIP服务', '提示', {
						confirmButtonText: '确定'
					});
				}
				
			}else{
				that.showLogin = false
				that.showLogin = true
			}
		},
		InsertCollection(){ //添加收藏
			var that = this
			if(that.$UserID){
				CheckInService.InsertUserColletion(that.$route.query.id,that.ImageModelInfo.ImageModel.image_name,that.$UserID,5).then(res=>{
					if(res.Tag == 1){
						that.collectStatus = 1
						that.$alert('收藏成功', '提示', {
							confirmButtonText: '确定'
						});
					}else{
						that.$alert(res.Message, '提示', {
							confirmButtonText: '确定'
						});
					}
				})
			}else{
				that.showLogin = false
				that.showLogin = true
			}
		},
		CancelCollection(){ //取消收藏
			var that = this
			if(that.$UserID){
				CheckInService.UpdateUserColletion(that.$UserID,that.$route.query.id).then(res=>{
					if(res.Tag == 1){
						that.collectStatus = 0
						that.$alert('取消收藏成功', '提示', {
							confirmButtonText: '确定'
						});
					}else{
						that.$alert(res.Message, '提示', {
							confirmButtonText: '确定'
						});
					}
				})
			}else{
				that.showLogin = false
				that.showLogin = true
			}
		},
		toElementDetail(value){ //词条详情
			if(value.type == 1 && value.ReleaseStatus != 0){
				window.open(this.$WebUrl + 'ElementDetail?id=' + value.id)
			}
		},
		toBookDetail(id){//图书详情
			window.open(this.$WebUrl + 'BookDetail?id=' + id)
		},
		toLogin(){
			this.showLogin = false
			this.showLogin = true
		}
	},
	mounted(){
		this.GetImageModel()
	},
	components:{
		'Header': Header,
		'Footer':Footer,
		'RelatedBook':RelatedBook,
		'RelatedElement':RelatedElement,
		'RelatedEntry':RelatedEntry,
		'RelatedImage':RelatedImage,
		'RelatedAudio':RelatedAudio,
		'RelatedVideo':RelatedVideo,
		'LoginPop':LoginPop,
		'ElImageViewer':ElImageViewer,
		'VIPLayer':VIPLayer
	}
}